<template>
  <div>
    <div class="center-image">
      <img
        v-if="center === 'JULIE'"
        class="pa-4"
        height="150"
        width="150"
        style="border-radius: 8%"
        src="@/assets/images/julie-811-center.svg"
        role="img"
        alt="Julie 811 Center Logo"
      />
      <img
        v-if="center === 'OHIO811'"
        class="pa-4"
        height="150"
        width="150"
        style="border-radius: 8%"
        src="@/assets/images/ohio-811.svg"
        role="img"
        alt="Ohio 811 Center Logo"
      />
      <img
        v-if="center === 'Blue Stakes of Utah 811'"
        class="pa-4"
        height="150"
        width="150"
        style="border-radius: 8%"
        src="@/assets/images/utah-811.svg"
        role="img"
        alt="UTAH 811 Center Logo"
      />
      <img
        v-if="center === 'NC811'"
        class="pa-4"
        height="150"
        width="150"
        style="border-radius: 8%"
        src="@/assets/images/NC811.svg"
        role="img"
        alt="NC 811 Center Logo"
      />
      <img
        v-if="center === 'CO811'"
        class="pa-4"
        height="150"
        width="150"
        style="border-radius: 8%"
        src="@/assets/images/CO811.svg"
        role="img"
        alt="Colorado 811 Center Logo"
      />
      <img
        v-if="center === 'VA811'"
        class="pa-4"
        height="150"
        width="150"
        style="border-radius: 8%"
        src="@/assets/images/VA811.svg"
        role="img"
        alt="VA 811 Center Logo"
      />
      <img
        v-if="center === 'DigAlert (USAS)'"
        class="pa-4"
        height="150"
        width="150"
        style="border-radius: 8%"
        src="@/assets/images/USAS-digalert-logo.svg"
        role="img"
        alt="USAS Digalert Logo"
      />
    </div>
    <div class="center-image">
      <v-card-subtitle class="py-0 font-weight-bold"
        >Response Not Required</v-card-subtitle
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ticket: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    center() {
      return this.ticket.center_name;
    },
  },
};
</script>

<style>
.center-image {
  display: flex;
  justify-content: space-around;
}
</style>
